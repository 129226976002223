.header {
    width: 100%;
    z-index: 50;
}

.footer{
    text-align: center;
    width: 100%;
    height: 55px;
    /* position:absolute; */
    left:0;
    bottom:0%;
    right:0;
}

.footer a:hover,
.footer a:active {
    color: tomato;
}

.contact-container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 0.5rem;
}


#watermark { position: fixed; top: 35%;
    left: 35%;  font-size: 100px; opacity: 0.5; white-space: nowrap;
    z-index: -100;
}

.contact-container a {
    flex-grow: 1;
    text-decoration: none;
    color: grey;
    padding: 0 0.5rem;
}

body {
    color: white;
    background-color:black;
    display: flex;
    flex-flow: column nowrap;
    /* overflow-x: auto; */
    /* white-space: nowrap; */
}
  

.container,
main {
    min-height: calc(100vh - 170px);
}

@media (max-width: 768px){ 
    body {
        /* display: none; */
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
    }
    #watermark {
        position: fixed;
        top: 50%;
        left: 20%;
        font-size: 50px;
    }
    .header{
        width: 100%;
    }
    main {
        width: 95%;
    }
    body iframe {
        width: 95%;
    }
}
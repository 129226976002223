.side-bar {
    color: white;
    height: 100%;
    background: white;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 300px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;

}
.side-bar.open {
    transform: translateX(0);
}
.side-bar ul {
   height: 100%;
   list-style: none; 
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.side-bar a {
    color: black;
    text-decoration: none;
}
.side-bar a:hover,
.side-bar a:active {
    color: tomato;
}

.side-bar li {
    margin: 0.5rem 0;

}

@media (min-width: 769px){ 
    .side-bar {
        display: none;
    }
}
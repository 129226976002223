.header {
    width: 100%;
    height: 55px;
    top: 0;
    left: 0;
    background-color: white;
    position: fixed;
}

.nav-bar {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
}

.nav-bar__logo {
    margin-left: 1rem;
}

.nav-bar__logo a{
    color: black;
    text-decoration: none;
    font-size: 2rem;
}

.spacer {
    flex: 1;
}


.nav-bar__items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.nav-bar__items li {
    padding: 0 0.5rem;
}

.nav-bar__items a{
    color: black;
    text-decoration: none;
}

.nav-bar__items a:hover,
.nav-bar__items a:active{
    color: tomato;
}


@media (max-width: 768px){
    .nav-bar__items {
        display: none;
    }
}

@media (min-width: 769px){
    .nav-bar__toggle-button {
        display: none;
    }
}
/* .nav-bar ul {
	min-width: 696px;
	list-style: none;
	padding: 5px;
}

.nav-bar ul li {
	display: inline;
    margin:0 20px 0 20px;
    color: black;
    font-size: 30px;

} */
